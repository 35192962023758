import React from 'react';
import './Footer.css';

const Footer = () => {
   return (
    <div className="footer">   
        <h5> Made with 🖤 by UWU Black Market</h5>
    </div>
   ) 
} 

export default Footer;