import React, { useState } from "react";
import "./PortfolioForm.css";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import Select from "react-select";

const PortfolioForm = ({ addStockSymbol, priceData }) => {
  const [selectedMemoji, setSelectedMemoji] = useState("");
  const [amount, setAmount] = useState("");
  const [avgCost, setAvgCost] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [errorMsgQuote, setErrorMsgQuote] = useState("");
  const [errorMsgAvgCost, setErrorMsgAvgCost] = useState("");
  const [errorMsgAmount, setErrorMsgAmount] = useState("");
  const [errorMsgPurchaseDate, setErrorMsgPurchaseDate] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopUp = () => setPopoverOpen(!popoverOpen);

  const handleChange = (selected) => {
    setSelectedMemoji(selected);
  };
  const handleChangeAmount = ({ target }) => {
    setAmount(target.value);
  };
  const handleChangeCost = ({ target }) => {
    setAvgCost(target.value);
  };

  const handlePurchaseDate = ({ target }) => {
    setPurchaseDate(target.value);
  };

  const validate = () => {
    if (!selectedMemoji) {
      setErrorMsgQuote("Emoji symbol cannot be blank");
      return false;
    }

    if (!avgCost) {
      setErrorMsgAvgCost("Average cost cannot be blank");
      return false;
    }
    if (!amount) {
      setErrorMsgAmount("Amount cannot be blank");
      return false;
    }

    if (!Number(avgCost)) {
      setErrorMsgAvgCost("Average cost must be number");
      return false;
    }

    let tradeDate = new Date(purchaseDate);
    let today = new Date();
    if (tradeDate > today) {
      setErrorMsgPurchaseDate("Purchase date cannot be from future 😄");
      return false;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();

    if (!isValid) {
      return false;
    } else {
      setErrorMsgQuote("");
      setErrorMsgAvgCost("");
      setErrorMsgAmount("");
    }

    addStockSymbol(selectedMemoji, avgCost, purchaseDate, amount);
    setSelectedMemoji("");
    setAvgCost("");
    setAmount("");
  };
  const handleCurrentPriceClick = (event) => {
    const foundPrice = priceData?.find(
      (p) => p.denom === selectedMemoji?.value
    );
    if (foundPrice) {
      setAvgCost(foundPrice?.price);
    }
  };

  return (
    <div className="form-container">
      <h1>🦄 Memoji Tracker 🦄</h1>
      <div className="popUpButton">
        <Button color="success" id="Popover1" type="button">
          <strong>?</strong>
        </Button>
        <Popover
          placement="bottom"
          isOpen={popoverOpen}
          target="Popover1"
          toggle={togglePopUp}
        >
          <PopoverHeader>User Guide</PopoverHeader>
          <PopoverBody>
            Portfolio information is saved in local storage. Don't clear your
            browser data or your holdings will be lost!
          </PopoverBody>
        </Popover>
      </div>
      <Form className="form-wrapper" onSubmit={handleSubmit}>
        <FormGroup row>
          <Label className="form-label" for="stock-symbol" sm={5}>
            Memoji
          </Label>
          <Col sm={2}>
            <Select
              name="stock-symbol"
              options={priceData?.map((p) => ({
                label: [
                  "factory/unicorn167xpap77dtenxsq2lzdgx52ntnce2v4h8f72epdwwrak6jxemsrqprj4xm/upurplephat",
                  "factory/unicorn1pawhaxskmdkzvfgevs0dh4lxuctn4x8wt2sqyz95tgem9ne2nrwqjg6rvq/udiamond",
                ].includes(p.denom) ? p.denomDisplay + ' (dup)' : p.denomDisplay,
                value: p.denom,
              }))}
              onChange={handleChange}
              value={selectedMemoji}
            />
            <div style={{ fontSize: 12, color: "red" }}>{errorMsgQuote}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="form-label" for="amount" sm={5}>
            Cost (🦄)
          </Label>
          <Col sm={2}>
            <Input
              type="number"
              name="amount"
              value={amount}
              onChange={handleChangeAmount}
              placeholder="2,000,000"
            />
            <div style={{ fontSize: 12, color: "red" }}>{errorMsgAmount}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="form-label" for="avg-cost" sm={5}>
            Price (🦄)
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="avg-cost"
              value={avgCost}
              onChange={handleChangeCost}
              placeholder="0.23"
            />
            <div style={{ fontSize: 12, color: "red" }}>{errorMsgAvgCost}</div>
            <a href="#" onClick={handleCurrentPriceClick}>
              Current Price
            </a>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="form-label" for="date" sm={5}>
            Purchase Date
          </Label>
          <Col sm={2}>
            <Input
              type="date"
              name="date"
              value={purchaseDate}
              onChange={handlePurchaseDate}
              placeholder="2010-04-17"
            />
            <div style={{ fontSize: 12, color: "red" }}>
              {errorMsgPurchaseDate}
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={2}></Col>
          <Col sm={2}></Col>
          <Col sm={2}>
            <Button color="primary">SUBMIT</Button>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
};

export default PortfolioForm;
