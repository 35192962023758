import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./Portfolio.css";
import Chart from "./Chart";

const numberFormatter = new Intl.NumberFormat(navigator.language, {
  notation: "compact",
  compactDisplay: "short",
});

const Portfolio = ({
  stock: { denom, denomDisplay, amount, cost, date, priceData, rateReturn },
  index,
  deleteStockSymbol,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [chartModal, setChartModal] = useState(false);
  const [invalidSymbolModal, setInvalidSymbolModal] = useState(true);
  const [missingData, setMissingData] = useState(false);

  const roundedRateReturn = rateReturn?.toFixed();

  // Toggle Modal
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleChartModal = () => setChartModal(!chartModal);
  const toggleInvalidSymbolModal = () =>
    setInvalidSymbolModal(!invalidSymbolModal);

  // Holding Period Calculation
  let oldDate = date.split("-");
  let formattedOldDate = new Date(oldDate[0], oldDate[1] - 1, oldDate[2]);
  let today = new Date();
  let diff = new Date(today.getTime() - formattedOldDate.getTime());
  let holdingPeriod = Math.floor(diff / (1000 * 3600 * 24));
  let HoldingPeriodYear = parseInt(holdingPeriod / 365);
  let HoldingPeriodDay = holdingPeriod % 365;

  const holdingPeriodChecker = () => {
    if (!date) {
      return <td> N/A </td>;
    }
    if (HoldingPeriodYear > 1) {
      return (
        <td>
          {" "}
          {HoldingPeriodYear} years {HoldingPeriodDay} days{" "}
        </td>
      );
    } else if (HoldingPeriodYear === 1) {
      return (
        <td>
          {" "}
          {HoldingPeriodYear} year {HoldingPeriodDay} days{" "}
        </td>
      );
    } else {
      return <td> {HoldingPeriodDay} days </td>;
    }
  };

  const rateReturnChecker = () => {
    if (roundedRateReturn > 0) {
      return (
        <td className="returnPositive">
          <a>{roundedRateReturn}%</a>
        </td>
      );
    } else {
      return (
        <td className="returnNegative">
          <a>{roundedRateReturn}%</a>
        </td>
      );
    }
  };

  return (
    <>
      {missingData ? (
        <Modal isOpen={invalidSymbolModal} toggle={toggleInvalidSymbolModal}>
          <ModalHeader
            style={{ color: "red" }}
            toggle={() => {
              deleteStockSymbol(index);
              toggleInvalidSymbolModal();
            }}
          >
            Invalid Emoji Symbol Entered
          </ModalHeader>
          <ModalBody>Please Enter A Valid emoji Symbol</ModalBody>
        </Modal>
      ) : (
        <tr className="sub-table-headings">
          <td style={{ width: "2rem" }}>{index + 1}</td>
          <td>{denomDisplay || denom}</td>
          <td>{priceData?.priceDisplay}</td>
          <td>{priceData?.mcapDisplay} 🦄</td>
          <td>
            {numberFormatter.format(amount)} {priceData?.emoji}
          </td>
          <td>{numberFormatter.format(cost)} 🦄</td>
          {holdingPeriodChecker()}
          {rateReturnChecker()}
          {/* <li ><span className="stockChart" onClick={toggleChartModal}><i class="fas fa-chart-line fa-2x"></i></span></li> */}
          <td style={{ borderStyle: "none" }}>
            <span className="deleteStock" onClick={toggleDeleteModal}>
              <i className="fas fa-trash fa-2x"></i>
            </span>
          </td>

          <Modal isOpen={chartModal} toggle={toggleChartModal}>
            <ModalHeader toggle={toggleChartModal}>
              5 Years Interactive Chart
            </ModalHeader>
            <Chart stockQuote={denom} />
          </Modal>

          <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
            <ModalHeader toggle={toggleDeleteModal}>
              Delete Confirmation
            </ModalHeader>
            <ModalBody>Are you sure you want to delete {denom}?</ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => {
                  deleteStockSymbol(index);
                  toggleDeleteModal();
                }}
              >
                Delete
              </Button>{" "}
              <Button color="secondary" onClick={toggleDeleteModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </tr>
      )}
    </>
  );
};

export default Portfolio;
