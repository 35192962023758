import React, { useState, useEffect } from "react";
import Portfolio from "./components/Portfolio";
import PortfolioForm from "./components/PortfolioForm";
import Footer from "./components/Footer";
import "./App.css";
import { CONTRACTS, ENDPOINTS, MEMOJI } from "./constants";
import { fetchBalancesAsync, findBalance } from "./hooks/balanceUtils.js";
import { useInterval } from "./hooks/useInterval.js";

const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});
const numberFormatter = new Intl.NumberFormat(navigator.language, {
  notation: "compact",
  compactDisplay: "short",
});

const percentFormatter = new Intl.NumberFormat(navigator.language, {
  style: "percent",
  minimumFractionDigits: 2,
});

function App() {
  const ALLSTOCKINFOS = localStorage.getItem("stockInfo")
    ? JSON.parse(localStorage.getItem("stockInfo"))
    : [];

  const [stockInfo, setStockInfo] = useState(ALLSTOCKINFOS);
  const [mergedStockInfo, setMergedStockInfo] = useState(ALLSTOCKINFOS);
  const [showLots, setShowLots] = useState(false);
  const [priceData, setPriceData] = useState();
  const [lastRefresh, setLastRefresh] = useState();
  const [sortedField, setSortedField] = useState("date");
  const [sortDirection, setSortDirection] = useState("desc");

  const mergeLots = (lots) => {
    const merged = lots?.reduce((acc, curr) => {
      const dup = acc?.find(
        (a) => a.priceData?.denom === curr.priceData?.denom
      );
      if (dup) {
        const others = acc.filter((a) => a.priceData?.denom !== dup.priceData?.denom);
        const newCost = dup.cost + curr.cost;
        const newAmount = dup.amount + curr.amount;
        const newRateReturn =
          ((dup.priceData?.price * newAmount - newCost) / newCost) * 100;
        const toreturn = [
          {
            ...dup,
            cost: newCost,
            amount: newAmount,
            rateReturn: newRateReturn,
          },
          ...others,
        ];
        return toreturn;
      } else {
        acc.push(curr);
        return acc;
      }
    }, []);

    return merged;
  };

  const fetchPrices = async () => {
    try {
      const controller = new AbortController();
      const signal = controller.signal;

      const supplyResponse = await fetch(ENDPOINTS.supply, { signal });
      const supplyData = await supplyResponse.json();
      const lpBalances = await fetchBalancesAsync(CONTRACTS.lp, signal);
      const getPair = async (denom) => {
        const res = await fetch(
          `${ENDPOINTS.factory}/${btoa(
            JSON.stringify({
              pair: {
                asset_infos: [
                  { native_token: { denom: denom } },
                  { native_token: { denom: "uwunicorn" } },
                ],
              },
            })
          )}`,
          { signal }
        );
        const data = await res.json();
        return data.data.contract_addr;
      };

      const getPriceAndTvl = async (denom) => {
        const pair = await getPair(denom);
        const balances = await fetchBalancesAsync(pair, signal);
        const ubal = findBalance(balances, "uwunicorn");
        const dbal = findBalance(balances, denom);
        return { price: ubal / dbal, tvl: ubal };
      };

      const getInfo = async (sup) => {
        const denom = sup.denom;
        const denomShorthand = sup.denom.split("/")?.[2];
        const supply = parseFloat(sup.amount) / 1000000;
        const lpBalance = findBalance(lpBalances, denom);
        const circ = supply - lpBalance;

        if (denom === "uwunicorn") {
          return {
            denom: "uwunicorn",
            denomShorthand: "uwunicorn",
            emoji: MEMOJI.find((x) => x.name === "uwunicorn")?.emoji,
            supply: supply,
            circ,
            mcap: supply,
            fdv: supply,
            tvl: 0,
            liq: 0,
            price: 1,
            balance: lpBalance,
            share: lpBalance / circ,
            value: lpBalance,
            listed: true,
          };
        } else {
          const priceAndTvl = await getPriceAndTvl(denom);
          const price = priceAndTvl.price;
          const tvl = priceAndTvl.tvl;
          const info = {
            denom: denom,
            denomShorthand,
            emoji: MEMOJI.find((x) => x.name === denomShorthand)?.emoji,
            supply: supply,
            circ,
            mcap: price * circ,
            fdv: price * supply,
            tvl,
            liq: tvl / (price * circ),
            price,
            balance: lpBalance,
            share: lpBalance / circ,
            value: lpBalance * price,
            listed: MEMOJI.find((x) => x.name === denomShorthand)?.listed
              ? 1
              : 0,
          };
          return info;
        }
      };

      const infos = await Promise.all(supplyData.supply.map(getInfo));
      const rowData = infos.map((info) => ({
        emoji: String(info.emoji),
        denom: String(info.denom),
        denomDisplay: info.denomShorthand,
        price: Number(info.price),
        priceDisplay: numberFormatter.format(info.price) + " 🦄",
        mcap: info.mcap,
        mcapDisplay: numberFormatter.format(info.mcap),
        liq: percentFormatter.format(info.liq),
        tvl: numberFormatter.format(info.tvl),
        fdv: numberFormatter.format(info.fdv),
        supply: info.supply,
        balance: info.balance,
        share: info.share,
        value: info.value,
        circ: info.circ,
        listed: info.listed,
      }));

      const newStockInfo = stockInfo.map((info) => {
        const priceData = rowData?.find(
            (p) => p?.denom === info?.priceData?.denom
          ),
          amount = info?.cost / info?.acquiredPrice;
        return {
          ...info,
          amount: amount,
          priceData: priceData,
          rateReturn:
            ((priceData?.price * amount - info?.cost) / info?.cost) * 100,
        };
      });

      setStockInfo(newStockInfo);
      setMergedStockInfo(mergeLots(newStockInfo));
      setPriceData(rowData);

      return () => {
        // cancel rest requests before component unmounts
        controller.abort();
      };
    } catch (error) {
      console.error("Error fetching market data: ", error);
    }
  };

  const sortGrid = (field) => {
    setSortedField(field);
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  };
  const handleShowMerged = (event) => {
    setShowLots(event.target.checked);
  };
  const addStockSymbol = (selected, acquiredPrice, date, cost) => {
    const foundPriceData = priceData?.find(
        (p) => p.denom === selected.value
      ),
      amount = cost / acquiredPrice;

    const newStockInfo = [
      ...stockInfo,
      {
        denom: selected.value,
        denomDisplay: selected.label,
        cost: Number(cost),
        acquiredPrice: Number(acquiredPrice),
        date: date,
        currentHolding: true,
        amount: amount,
        priceData: foundPriceData,
        rateReturn: ((foundPriceData?.price * amount - cost) / cost) * 100,
      },
    ];
    setStockInfo(newStockInfo);
    setMergedStockInfo(mergeLots(newStockInfo));
  };

  const deleteStockSymbol = (index) => {
    const newStockInfo = [...stockInfo];
    newStockInfo.splice(index, 1);
    setStockInfo(newStockInfo);
    setMergedStockInfo(mergeLots(newStockInfo));
  };

  useEffect(() => {
    window.localStorage.setItem("stockInfo", JSON.stringify(stockInfo));
  }, [stockInfo]);

  useEffect(() => {
    fetchPrices();
    setLastRefresh(Date.now());
  }, []);

  useEffect(() => {
    if (sortDirection === "desc") {
      if (!sortedField.includes(".")) {
        const newStockInfo = [
          ...stockInfo.sort((a, b) => a[sortedField] < b[sortedField]),
        ];
        setStockInfo(newStockInfo);
      } else {
        const split = sortedField.split(".");
        const newStockInfo = [
          ...stockInfo.sort(
            (a, b) => a[split[0]][split[1]] < b[split[0]][split[1]]
          ),
        ];
        setStockInfo(newStockInfo);
      }
    } else {
      if (!sortedField.includes(".")) {
        const newStockInfo = [
          ...stockInfo.sort((a, b) => a[sortedField] > b[sortedField]),
        ];
        setStockInfo(newStockInfo);
      } else {
        const split = sortedField.split(".");
        const newStockInfo = [
          ...stockInfo.sort(
            (a, b) => a[split[0]][split[1]] > b[split[0]][split[1]]
          ),
        ];
        setStockInfo(newStockInfo);
      }
    }
  }, [sortedField, sortDirection]);

  useInterval(async () => {
    await fetchPrices();
    setLastRefresh(Date.now());
  }, 60000);

  return (
    <div>
      <PortfolioForm addStockSymbol={addStockSymbol} priceData={priceData} />
      <p>
        <input type="checkbox" onClick={handleShowMerged} /> Show Lots
      </p>
      <table>
        <thead>
          <tr className="table-headings">
            <th>#</th>
            <th onClick={() => sortGrid("denom")}>Memoji</th>
            <th onClick={() => sortGrid("priceData.price")}>Current Price</th>
            <th onClick={() => sortGrid("priceData.mcap")}>Current MC</th>
            <th onClick={() => sortGrid("amount")}>Amount</th>
            <th onClick={() => sortGrid("cost")}>Cost</th>
            <th onClick={() => sortGrid("date")}>Holding Period</th>
            <th onClick={() => sortGrid("rateReturn")}>Return</th>
            {/* <th>Chart</th> */}
            <th style={{ borderStyle: "none" }}></th>
          </tr>
        </thead>
        <tbody>
          {showLots
            ? stockInfo.map((stock, index) => (
                <Portfolio
                  key={stock.id}
                  index={index}
                  stock={stock}
                  deleteStockSymbol={deleteStockSymbol}
                />
              ))
            : mergedStockInfo.map((stock, index) => (
                <Portfolio
                  key={stock.id}
                  index={index}
                  stock={stock}
                  deleteStockSymbol={deleteStockSymbol}
                />
              ))}
        </tbody>
      </table>
      Last Refresh: {dateFormatter.format(new Date(lastRefresh || 0))}
      <Footer />
    </div>
  );
}

export default App;
